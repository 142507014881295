

.display-5 {
  font-size: calc(1.5rem + 2.1vw); /* Slightly larger base size */
  font-weight: 800;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* Target all heading levels */
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #0e2e50;
}

h1,
h2 {
  /* Target h1 and h2 specifically for font size */
  font-size: calc(1.375rem + 1.5vw);
}

/* Styles for tab content */
.tab-content > .tab-pane {
  display: none; /* Hide all tab panes by default */
}

.tab-content > .active {
  display: block; /* Show only the active tab pane */
}

a {
  color: #0f2e4f; /* Sets the default text color for links */
  text-decoration: none; /* Removes default underline on links */
}

/* Styles for links on hover */
a:hover {
  color: #4f2f10; /* Changes text color on hover */
}

.btn-outline-light {
    color: #effdf5;
    border-color: #effdf5;
  }
  
  .btn-outline-light:hover {
    color: #000;
    background-color: #effdf5;
    border-color: #effdf5;
  }
  
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(239, 253, 245, 0.5);
  }
  
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light,
  .btn-outline-light:active,
  .btn-outline-light.active,
  .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #effdf5;
    border-color: #effdf5;
  }
  
  .btn-check:checked + .btn-outline-light:focus,
  .btn-check:active + .btn-outline-light:focus,
  .btn-outline-light:active:focus,
  .btn-outline-light.active:focus,
  .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(239, 253, 245, 0.5);
  }
  
  .btn-outline-light:disabled,
  .btn-outline-light.disabled {
    color: #effdf5;
    background-color: transparent;
  }
  
  .btn-outline-dark {
    color: #0e2e50;
    border-color: #0e2e50;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #0e2e50;
    border-color: #0e2e50;
  }
  

@media (min-width: 1200px) {
  h1,
  h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.text-primary {
  color: #03a9f4 !important;
}
